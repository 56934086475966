@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

img {
    user-select: none;
}

body, html, :root {
    height: 100%;
}

a {
    text-decoration: none !important;
}

input {
    outline: none;
    box-shadow: none;
}

input:focus-visible {
    outline: none;
}

.hexagon {
    width: 150px;
    height: 87px;
    background-color: #ffffff;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.hexagon:before,
.hexagon:after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
}

.hexagon:before {
    top: -29px;
    border-bottom: 30px solid #ffffff;
}

.hexagon:after {
    bottom: -29px;
    border-top: 29px solid #ffffff;
}

.logo-block:hover a {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.dropdown {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 10px 20px -12px;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    z-index: 1;
}
.dropdown::after {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 12px 12px;
    border-color: transparent transparent rgb(255, 255, 255);
    top: -12px;
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}
.dropdown::before {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 12px 12px;
    border-color: transparent transparent rgb(212, 219, 232);
    top: -13px;
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}
.rotateX-180 {
    rotate: 180deg;
}
.rotateY-180 {
    transform: rotateY(180deg);
}
.rcp-saturation {
    border-radius: 6px !important;
}
.rcp-body {
    padding: 20px 0px !important;
}
.rcp-fields-floor {
    display: none !important;
}
.rcp-fields-floor:first-child {
    display: block !important;
}
.rcp-field-label {
    display: none !important;
}
.rcp-field-input {
    border: none !important;
    padding: 8px !important;
    color: rgb(9, 11, 23) !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    background: rgba(0, 0, 0, 0.05) !important;
    border-radius: 6px !important;
    width: 100% !important;
    outline: none !important;
}
.cirlce-hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(6%, 6%);
    width: 90%;
    height: 90%;
    background: transparent;
    border: 2px solid transparent;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}
.cirlce-active::after,
.cirlce-hover:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(6%, 6%);
    width: 90%;
    height: 90%;
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 50%;
}
.input-focus:focus ~ label {
    top: 16px;
    font-size: 12px;
}
.color-hover:hover div {
    top: 0px;
}
.color-hover:hover div p {
    opacity: 1;
}
.color-hover div {
    transition: all 0.3s ease-in-out;
    top: 42px;
}
.color-hover div p {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.color-hover-text {
    opacity: 1;
    top: 0 !important;
}
.color-hover-text p {
    opacity: 1 !important;
}
.logo-maker-step-two-color-hover-text {
    font-weight: 600;
    font-family: "Inter";
}
@media (max-width: 1200px) {
    .input-focus:focus ~ label {
        top: 16px;
        font-size: 7px;
    }
}


/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 0;
    top: 0;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #1E1E1E;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    top: 16px !important;
    right: 16px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: calc(100% - 75px) !important;
    width: 250px !important;
    margin-right: 8px;
    bottom: 8px;
    border-radius: 16px;
    overflow: hidden;
}

/* General sidebar styles */
.bm-menu {
    background: #1E1E1E;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
}
.bm-item-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.bm-item {
    display: flex !important;
    flex-direction: column;

}
.slick-prev:before,.slick-next:before, [dir=rtl] .slick-prev:before {
    display: none !important;
}

.scroll-hidden::-webkit-scrollbar {
    width: 0;
}
  
.scroll-hidden {
    scrollbar-width: none;
}

.scroll-hidden::-webkit-scrollbar-thumb {
    background: transparent;
}

.scroll-hidden::-webkit-scrollbar-track {
    background: transparent; 
}

.modal-content {
    border: none !important;
    border-radius: 16px !important;
    overflow: hidden !important;
    box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.29) !important;
    background-color: #2E2E2E;
}

.single-thumb .range-slider__thumb[data-lower] {
    width: 0;
}

.single-thumb .range-slider__range {
    border-radius: 6px;
}
  
.stripe-loader {
    width: 3.25em;
    transform-origin: center;
    animation: rotate4 2s linear infinite;
}
   
.stripe-loader circle {
    fill: none;
    stroke: hsl(214, 97%, 59%);
    stroke-width: 3;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
    100% {
        transform: rotate(360deg);
    }
}
   
@keyframes dash4 {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
   
    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }
   
    100% {
        stroke-dashoffset: -125px;
    }
}

.checkout-error-block {
    display: flex;
    padding: 16px;
    justify-content: start;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #F87171;
    background: rgba(248, 113, 113, 0.20);
    color: #F87171;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.22px;
    text-align: start;
    margin-bottom: 40px;
}

.help-section-wrapper {
    position: relative;
}

.text-hidden-password {
    -webkit-text-security: disc;
}

@media (max-width: 1200px) {
    .checkout-error-block {
        display: flex;
        padding: 16px;
        justify-content: start;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #F87171;
        background: rgba(248, 113, 113, 0.20);
        color: #F87171;
        font-family: "Inter";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.22px;
        text-align: start;
        margin-top: 24px;
        margin-bottom: 0px;
    }

    .close-icon {
        width: 15px;
    }
}